import styled, { css } from "styled-components";
import { IButtonEmotionProps } from "./i-button";

function variantButtonStyles({ variant }: IButtonEmotionProps) {
   switch (variant) {
      case "outline":
         return css`
            color: ${({ theme }) => theme.colors?.white};
            background: none;
            border: 1px solid ${({ theme }) => theme.colors?.white};

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${({ theme }) => theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      default:
      case "primary":
         return css`
            color: ${({ theme }) => theme.colors?.white};
            background-color: ${({ theme }) => theme.colors?.primary};
            border: none;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35);

            &:hover,
            &:active {
               color: ${({ theme }) => theme.colors?.white};
               background-color: ${({ theme }) => theme.colors?.primary};
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${({ theme }) => theme.colors?.white};
               border-color: ${({ theme }) => theme.colors?.grey};
               background-color: ${({ theme }) => theme.colors?.primary};
               cursor: not-allowed;
               opacity: 0.4;
            }
         `;
   }
}

export const ButtonStyledComponent = styled.button`
   cursor: pointer;
   box-sizing: border-box;
   max-width: 100%;
   text-align: center;
   outline: none;
   overflow: hidden;
   width: ${({ width }) => width}px;
   height: 55px;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: ${({ size }) => (size === "small" ? "14px" : "14px")};
   letter-spacing: 0;
   line-height: 1.6em;
   border-radius: 12px;
   text-transform: capitalize;
   transition: all 300ms ease-in-out;
   background-color: ${({ theme }) => theme.colors?.grey};
   color: ${({ theme }) => theme.colors?.grey};
   font-weight: ${({ theme }) => theme.fontWeight?.medium};
   letter-spacing: 1.5px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: ${({ size }) => {
      switch (size) {
         case "small":
            return "1.2em 1.8em;";
         default:
            return "1.5rem 2.2em";
      }
   }};

   ${({ fullWidth }) =>
      fullWidth &&
      css`
         width: 100%;
      `};

   ${variantButtonStyles}

   &.ripple {
      background-position: center;
      transition: background 0.8s;
      outline: none;
   }
   &.ripple:hover {
      background: #7211d4 radial-gradient(circle, transparent 1%, #7211d4 1%) center/15000%;
   }
   &.ripple:active {
      background-color: #fff;
      background-size: 100%;
      border: none;
      outline: none;
      transition: background 0s;
   }
`;
