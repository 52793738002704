/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable @next/next/no-script-component-in-head */
import Head from "next/head";
import { useRouter } from "next/router";

interface Props {
   title: string;
   children: React.ReactNode;
   description?: string;
   keywords?: string;
   image?: string;
}

export const Meta = ({ title, children, description, keywords, image }: Props) => {
   const site = "https://metricks.io";
   const router = useRouter();
   const _pathSliceLength = Math.min.apply(Math, [
      router.asPath.indexOf("?") > 0 ? router.asPath.indexOf("?") : router.asPath.length,
      router.asPath.indexOf("#") > 0 ? router.asPath.indexOf("#") : router.asPath.length,
   ]);
   const canonicalURL = site + router.asPath.substring(0, _pathSliceLength);
   return (
      <>
         <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content="Metricks" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalURL} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={title} />
            <meta property="og:image:secure_url" content={image} />

            <meta charSet="utf-8" />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta
               name="google-site-verification"
               content="2JGzO4I8b8pdAyJYAt2DP66eZKxXIgh8wUD_6XOH9RI"
            />
            <script
               dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-PFQ7VQ3');`,
               }}
            />
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-412127846"></script>
            <script
               dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'AW-412127846');`,
               }}
            />
            <script
               dangerouslySetInnerHTML={{
                  __html: `
            !function(e,t,n,a,c,s,r){e[a]||((c=e[a]=function(){c.process?c.process.apply(c,arguments):c.queue.push(arguments)}).queue=[],c.t=+new Date,(s=t.createElement(n)).async=1,s.src="https://script.metricks.io/tracker.min.js?t="+864e5*Math.ceil(new Date/864e5),(r=t.getElementsByTagName(n)[0]).parentNode.insertBefore(s,r))}(window,document,"script","metr"),metr("init","met_live_U2FsdGVkX19z7Dw2uKxuzMFk/iYMqVePnVHIICl1nyY=");
            `,
               }}
            />
            <link
               rel="icon"
               href="https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1-100x100.png"
               sizes="32x32"
            />
            <link
               rel="icon"
               href="https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1.png"
               sizes="192x192"
            />
            <link
               rel="apple-touch-icon"
               href="https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1.png"
            ></link>
            <link rel="canonical" href={canonicalURL} />
         </Head>
         <>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PFQ7VQ3"
               height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
            {children}</>
      </>
   );
};