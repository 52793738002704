import router from "next/router";
import { BrandImageStyledContainer, BrandImageStyledComponent } from "./brand-image.styles";

const BrandImage = () => {
   return (
      <BrandImageStyledContainer onClick={() => router.push("/")}>
         <BrandImageStyledComponent src="/images/metricks.png" alt="icon" />
      </BrandImageStyledContainer>
   );
};

const BrandImageWhite = () => {
   return (
      <BrandImageStyledContainer onClick={() => router.push("/")}>
         <BrandImageStyledComponent src="/images/metricks-white.png" alt="icon" />
      </BrandImageStyledContainer>
   );
};

export { BrandImage, BrandImageWhite };
